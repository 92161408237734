.black-lion-project {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.link-section {
  display: flex;
  justify-content: center;
  gap: 20px; /* Spacing between buttons */
  margin: 20px 0;
  flex-wrap: wrap; /* Allows buttons to wrap to the next line */
}

.github-link, .website-link {
  display: inline-block;
  background-color: #24292e; /* Default GitHub link color */
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-align: center; /* Centers text inside the button */
}

.github-link:hover {
  background-color: #444c56;
}

.website-link {
  background-color: #0073e6; /* Blue color for the deployed website link */
}

.website-link:hover {
  background-color: #005bb5; /* Darker blue on hover */
}

.content-wrapper {
  max-width: 800px;
  width: 100%;
  text-align: left;
}

.content-wrapper h2 {
  color: #2c3e50;
  margin-top: 20px;
}

.content-wrapper ul, .content-wrapper p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.diagram-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.architecture-diagram {
  max-width: 80%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Deployed Pages Section */
.deployed-page-image {
  width: 200px;
  cursor: pointer;
  margin: 10px;
  transition: transform 0.2s;
}

.deployed-page-image:hover {
  transform: scale(1.05);
}

.deployed-pages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.deployed-page {
  text-align: center;
  margin: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
}

.modal-image {
  max-width: 80%;
  max-height: 80%;
}

.modal:hover {
  cursor: zoom-out;
}

/* Responsive Design for Mobile View */
@media (max-width: 600px) {
  .link-section {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Reduce gap for vertical buttons */
  }

  .github-link, .website-link {
    width: 100%; /* Make buttons full width */
    max-width: 300px; /* Optional: Limit button width */
  }
}
