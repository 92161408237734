.react-app-website {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .project-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .link-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
    flex-wrap: wrap; /* Allows links to wrap to the next line on smaller screens */
  }
  
  .github-link, .website-link {
    display: inline-block;
    background-color: #24292e; /* GitHub link color */
    color: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1.2em;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .github-link:hover {
    background-color: #444c56;
  }
  
  .website-link {
    background-color: #0073e6; /* Website link color */
  }
  
  .website-link:hover {
    background-color: #005bb5;
  }
  
  .content-wrapper {
    max-width: 800px;
    width: 100%;
    text-align: left;
  }
  
  .content-wrapper h2 {
    color: #2c3e50;
    margin-top: 20px;
  }
  
  .content-wrapper ul, .content-wrapper p {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .diagram-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .architecture-diagram {
    max-width: 80%; /* Increase the maximum width to 80% of the container */
    width: 800px; /* Set a fixed width for larger screens */
    height: auto; /* Maintain aspect ratio */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 600px) {
    .architecture-diagram {
      max-width: 100%; /* Allow full width on mobile */
      width: 100%; /* Ensure it scales down properly */
    }  
  
    .github-link, .website-link {
      width: 100%; /* Full width for links */
      max-width: 300px; /* Optional: Limit button width */
    }
  }
  