.data-analysis {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .project-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .link-section {
    display: flex;
    justify-content: center;
    gap: 20px; /* Spacing between buttons */
    margin: 20px 0;
    flex-wrap: wrap; /* Allows buttons to wrap to the next line */
  }
  
  .github-link-wrapper {
    display: inline-block;
  }
  
  .github-link {
    display: inline-block;
    background-color: #24292e; /* GitHub link color */
    color: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1.2em;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .github-link:hover {
    background-color: #444c56;
  }
  
  .content-wrapper {
    max-width: 800px;
    width: 100%;
    text-align: left;
  }
  
  .content-wrapper h2 {
    color: #2c3e50;
    margin-top: 20px;
  }
  
  .content-wrapper ul, .content-wrapper p {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .content-wrapper a {
    color: #0073e6;
    text-decoration: none;
  }
  
  .content-wrapper a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design for Mobile View */
  @media (max-width: 600px) {
    .link-section {
      flex-direction: column; /* Stack buttons vertically */
      gap: 10px; /* Reduce gap for vertical buttons */
    }
  
    .github-link {
      width: 100%;
      max-width: 300px; /* Optional: Limit button width */
    }
  }
  