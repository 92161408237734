.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #000000;
  }

  .profile {
    width: 100%;
    text-align: center;
    position: relative; /* Ensure positioning is relative to this container */
    }
    
    .banner {
    position: relative;
    width: 100%;
    background-size: cover; /* Cover the entire area of the header */
    background-position: center center;
    }
  
    
    .banner-image {
      width: 100% !important;
      height: 140px; /* Set a fixed height, or adjust as needed */
      object-fit: cover; /* This ensures the image covers the area without being distorted */
    }
    
    .profile-picture {
    width: 180px; /* Adjust as necessary */
    height: 180px; /* Adjust as necessary */
    border-radius: 50%; /* Circular picture */
    box-shadow: 10px 10px 15px rgb(0, 0, 0);
    position: absolute;
    border: 5px solid rgb(255, 255, 255); /* Optional: Adds a border around the picture */
    transform: translateX(20%) translateY(-60%); /* Center horizontally and push down half outside the banner */
    }
  
    @media screen and (max-width: 600px) {
      .profile-picture {
        width: 120px; /* Adjust size */
        height: 120px; /* Adjust size */
        max-width: 100%;
      }
    }
  
  .about {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #000000;
    color: #f0f0f0;
    padding-bottom: 60px;
  }
  
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    color: #58caf4;
    height: 50px;
  }
  
  .about .prompt {
    width: 40%;
    font-size: 30px;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .skills h1 {
    color: #000000;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
  }
  
  .list span {
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .about h2 {
      padding-top: 60px;
      font-size: 40px;
    }
  
    .about .prompt {
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }